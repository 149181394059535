

export default function Button({id="", className="", onClick=()=>{}, onMouseEnter=(hover)=>{}, onMouseLeave=(hover)=>{}, children}){
    let classes = "app-button " + className ? className : "";
    return(
        <button
            onMouseEnter={()=> onMouseEnter(true)}
            onMouseLeave={()=> onMouseLeave(false)}
            id={id}
            onClick={onClick} 
            className={classes}>
            {children}
        </button>
    );
}