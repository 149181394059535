import React from 'react';
import { Route, Routes, Navigate } from 'react-router';
import LandingPage from './components/landingPage/LandingPage';
import { Layout } from './components/layout/Layout';
import { dictionariesCodes, getDefaultLanguage } from './services/dictionaryService';
import StaticFileNavigator from './components/common/StaticFileNavigator';
import "../src/scss/App.scss";
import { getBusinessDocuments, getBusinessDocumentsWithAbsolutePathAsRoot } from './services/businessDocumentsService';

const defaultPublicDocuments = getBusinessDocumentsWithAbsolutePathAsRoot();
const publicDocuments = dictionariesCodes.map((langCode) => getBusinessDocuments(langCode));
publicDocuments.push(defaultPublicDocuments);

export default function App() {
	return (
		<Routes>
			<Route path='/' element={<Layout lang={getDefaultLanguage()} main={<LandingPage />} />} />
			{dictionariesCodes.map((langCode) =>
				<Route path={langCode} element={<Layout lang={langCode} main={<LandingPage />} />} key={langCode} />
			)}
			{publicDocuments.map((languageDocuments, index) =>
				<React.Fragment key={index}>
					<Route path={languageDocuments.faq.absolutePath} key={languageDocuments.faq.absolutePath} element={<StaticFileNavigator filePath={languageDocuments.faq.fileEndpoint} />} />
					<Route path={languageDocuments.discountTerms.absolutePath} key={languageDocuments.discountTerms.absolutePath} element={<StaticFileNavigator filePath={languageDocuments.discountTerms.fileEndpoint} />} />
					<Route path={languageDocuments.privacyPolicy.absolutePath} key={languageDocuments.privacyPolicy.absolutePath} element={<StaticFileNavigator filePath={languageDocuments.privacyPolicy.fileEndpoint} />} />
					<Route path={languageDocuments.appPrivacyPolicy.absolutePath} key={languageDocuments.appPrivacyPolicy.absolutePath} element={<StaticFileNavigator filePath={languageDocuments.appPrivacyPolicy.fileEndpoint} />} />
					<Route path={languageDocuments.inperlyTerms.absolutePath} key={languageDocuments.inperlyTerms.absolutePath} element={<StaticFileNavigator filePath={languageDocuments.inperlyTerms.fileEndpoint} />} />
				</React.Fragment>
			)}
			<Route path='*' element={<Navigate to="/" />} />
		</Routes>
	);
}
