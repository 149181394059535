
function ForEachChild(componentProps, iteration){
    return componentProps.children.map((child)=>{
        iteration(child);
    });
}

function NewLiner(text){
    return text.split('\n').map(function(item, key) {
        return (
            <span key={key}>
                {item}
                <br/>
            </span>
        );
    });
}

export { ForEachChild, NewLiner };