import Button from "./Button";
import React, { useCallback, useState } from "react";
import {IconCarretDown, IconCarretUp} from "./Icons";
import Stack from "../common/Stack";

const Dropdown = ({children, buttonContent, withCarret=true}) => {
    const [menuVisible, setMenuVisible] = useState(false);

    const showMenu = useCallback(()=>{
        setMenuVisible(!menuVisible);
    },[menuVisible]);

    return(
        <div className="dropdown">
            <Button onClick={showMenu}>
                <Stack spaceBetween={2}>
                    <span style={{marginRight:"16px"}}>{buttonContent}</span>
                    {withCarret ? menuVisible ? <IconCarretUp/> : <IconCarretDown/> : <></>}
                </Stack>
            </Button>
            {menuVisible && <div className="dropdown-menu">{children}</div>}
        </div>
    );
}

const DropdownItem = ({children, className = "", ...props}) => {
    return <a className={`dropdown-item ${className}`} {...props}>{children}</a>;
}

export {Dropdown, DropdownItem};