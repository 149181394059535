
import { useContext, createContext } from 'react';
import dictionaryPL from '../resources/languages/dictionary.pl.json';
import dictionaryEN from '../resources/languages/dictionary.en.json';
import dictionaryDE from '../resources/languages/dictionary.de.json';

const dictionaries = {
  pl: dictionaryPL,
  en: dictionaryEN,
  de: dictionaryDE,
};

export const dictionariesCodes = Object.getOwnPropertyNames(dictionaries);

export function getDefaultLanguage(){
  const langCode = window.navigator.language.split('-', 2)[0].toLowerCase();

  if (dictionaries.hasOwnProperty(langCode)) {
    return langCode;
  } else {
    return "en";
  }
}

const defaultLanguage = getDefaultLanguage();

const normalizeLang = (lang) => {
  lang = (lang ?? '').toLowerCase();
  return dictionaries.hasOwnProperty(lang) ? lang : defaultLanguage;
};

export const LanguageContext = createContext();

export const LanguageContextProvider = ({ value, children }) => {
  return <LanguageContext.Provider value={normalizeLang(value)}>{children}</LanguageContext.Provider>;
};

export const useDictionary = (key) => {
  const language = useContext(LanguageContext);
  return dictionaries[language].landingPage[key];
};

export const useDictionariesSelection = (key) => {
  const language = useContext(LanguageContext);
  return dictionaries[language].languageSelector[key];
}