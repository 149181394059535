import { GenerateUUID } from "../../services/Toolbox";

function Grid({children, columnsSize=[]}){
    if(columnsSize.length === 0){
        return(
            <div className="app-grid">
                {children}
            </div>
        );
    }
    const getColumnCssClass = (colSize) => "app-grid-column-"+colSize;
    let elements = [];
    for (let index = 0; index < children.length; index++) {
        const element = children[index];
        elements.push(<div className={getColumnCssClass(columnsSize[index])} key={GenerateUUID()}>{element}</div>);
    }
    return(
        <div className="app-grid">
            {elements}
        </div>
    );
}

export default Grid;