
import React from "react";
import Carousel from "../../common/Carousel";
import HorizontalContainer from "../../common/HorizontalContainer";
import { ForEachMap } from "../../../services/Toolbox";
import { MockupImageUrl } from "../../../resources/mockup/MockAssets";
import { useDictionary} from "../../../services/dictionaryService";
import AppConfig from "../../../AppConfig.json";

export default function Introduction() {
    const dict = useDictionary('introduction');

    return (
        <div id="landingpage-introduction-container">
            <div className="app-container">
                <div id="introduction-carousel">
                    <Carousel>
                        {dict.slides.map((item, index)=>
                            <div key={index}>
                                <div key={0} 
                                    className="app-margin-right">
                                    <h2>{item.header}</h2>
                                    <p>{item.text}</p>
                                </div>
                                <div key={1}>
                                    <img 
                                        className="app-width-full" 
                                        src={AppConfig.media.introduction[index]}/>
                                </div>
                            </div>
                        )}
                    </Carousel>
                </div>
            </div>
        </div>
    );
}