
function CssClassBuilder(classes = []){
    let cssClass = "";
    classes.forEach(item => {
        cssClass += item ? item + " " : "";
    });
    return cssClass;
}

export{
    CssClassBuilder
};