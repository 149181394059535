import Navbar from "./Navbar";
import { Footer } from './Footer';
import { LanguageContextProvider } from '../../services/dictionaryService';

export function Layout({ lang, main }) {
  return (
    <LanguageContextProvider value={lang}>
      <div>
        <Navbar />
        <main>
            {main}
        </main>
        <Footer/>
      </div>
    </LanguageContextProvider>
  );
}
