import AppConfig from "../../../AppConfig.json";
import AppContext from "../../../AppContext";
import {useState, useEffect} from "react";
import "./ProductPrice.scss"

const currency = Object.getOwnPropertyNames(AppConfig.price)[0];
const price = AppConfig.price.PLN;

export const ProductPrice = ({...props}) => {
    const [priceDiscount, setPriceDiscount] = useState("");

    useEffect(()=>{
        setPriceDiscount(AppContext.ctaWidgetPromotion);
    }, [AppContext.ctaWidgetPromotion])

    return <span {...props}><s className="productprice-price">{price} {currency}</s> <b className="productprice-price-discount">{priceDiscount} {currency}</b></span>;
}