import LandingPageComponent from "../../common/LandingPageComponent";
import { useDictionary } from "../../../services/dictionaryService";
import { GenerateUUID } from "../../../services/Toolbox";
import Card from "../../common/Card";
import Grid from "../../common/Grid";

const BuisnessInfoSection = ({title, subTitle, list})=>{
    return(
        <div id="buisnessInfoSection-column">
            <Card>
                <div className="app-text-center app-margin-bottom-8px">
                    <div className="buisnessInfoSection-title app-margin-bottom-8px">{title}</div>
                    <span className="businessInfoSection-caption">{subTitle}</span>
                </div>                
                <ul>
                    {list.map((record, index)=> <li key={index}>{record}</li>)}
                </ul>
            </Card>
        </div>
    );
}

function BuisnessInfo() {
    const dict = useDictionary('buisnessInfo');

    return(
        <LandingPageComponent componentId="landingpage-buisnessinfo-container">
            <h1>{dict.header}</h1>
            <div id="businessinfo-sections">
                {dict.buisnesses.map( (record, index)=> 
                    <BuisnessInfoSection
                        key={index}
                        title={record.title}
                        subTitle={record.subTitle}
                        list={record.description}/> )}
            </div>
        </LandingPageComponent>
    );
}

export default BuisnessInfo;