
import ReactPlayer from 'react-player';

function VideoPlayer(props){
    return(
        <ReactPlayer
            width="100%"
            height="100%"
            url={props.source}
            controls={true}/>
    );
}

function VimeoPlayer({source, title}){
    return(
        <div style={{padding:"75% 0 0 0",position:"relative"}}>
            <iframe src={source} 
                frameBorder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowFullScreen 
                style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}}
                title={title}>
            </iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>    
        </div>
    );
}

export {
    VideoPlayer,
    VimeoPlayer
};