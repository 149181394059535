

import React from "react";
import FaIcon from "../../resources/Fork-Awesome-1.2.0/css/fork-awesome.css"

function Icon({iconKey, className}){
    return (
        <div className={className}><i className={iconKey} aria-hidden="true"/></div>
    );
}


function IconCheckCircle({className}){
    return (
        <Icon className={className} iconKey="fa fa-check-circle-o"/>
    );
}

function IconLanguage({className}){
    return (
        <Icon className={className} iconKey="app-icon-language"/>
    );
}

function IconLinkedin({className}){
    return (
        <Icon className={className} iconKey="fa fa-linkedin-square"/>
    );
}

function IconTwitter({className}){
    return (
        <Icon className={className} iconKey="fa fa-twitter-square"/>
    );
}

function IconCarretDown({className}){
    return (
        <Icon className={className} iconKey="fa fa-caret-down"/>
    );
}

function IconCarretUp({className}){
    return (
        <Icon className={className} iconKey="fa fa-caret-up"/>
    );
}

export {
    IconLanguage, 
    IconLinkedin, 
    IconTwitter,
    IconCarretDown,
    IconCheckCircle,
    IconCarretUp
};

