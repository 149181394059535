import { useContext } from 'react';
import { LanguageContext } from './dictionaryService';
import AppConfig from "../AppConfig.json";

const publicDocuments = AppConfig.publicDocuments;
const defaultLanguage = "en";

export const useBusinessDocumentsWithLangFromContext = () => {
    const contextLanguage = useContext(LanguageContext);
    return getBusinessDocuments(contextLanguage);
};

export const getBusinessDocuments = (language) => {
    const currentDocuments = publicDocuments[language];
    return {
        faq: { path: "faq", absolutePath: language + "/faq", fileEndpoint: currentDocuments.faq },
        discountTerms: { path: "discount-terms", absolutePath: language + "/discount-terms", fileEndpoint: currentDocuments.discountTerms },
        privacyPolicy: { path: "privacy-policy", absolutePath: language + "/privacy-policy", fileEndpoint: currentDocuments.privacyPolicy },
        appPrivacyPolicy: { path: "app-privacy-policy", absolutePath: language + "/app-privacy-policy", fileEndpoint: currentDocuments.appPrivacyPolicy },
        inperlyTerms: { path: "inperly-terms", absolutePath: language + "/inperly-terms", fileEndpoint: currentDocuments.inperlyTerms }
    }
};

export const getBusinessDocumentsWithAbsolutePathAsRoot = () => {
    const documents = getBusinessDocuments(defaultLanguage);
    documents.faq.absolutePath = documents.faq.path;
    documents.discountTerms.absolutePath = documents.discountTerms.path;
    documents.privacyPolicy.absolutePath = documents.privacyPolicy.path;
    documents.appPrivacyPolicy.absolutePath = documents.appPrivacyPolicy.path;
    documents.inperlyTerms.absolutePath = documents.inperlyTerms.path;
    return documents;
};
