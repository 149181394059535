
import React, { useEffect, useState } from "react";
import { useDictionary} from "../../../services/dictionaryService";
import Button from "../../common/Button";
import HorizontalContainer from "../../common/HorizontalContainer";
import { MockupImageUrl } from "../../../resources/mockup/MockAssets";
import CallToActionPopup from "./CallToActionPopup";
import { ShowModal } from "../../common/Modal";
import AppConfig from "../../../AppConfig.json";
import CallToActionWidget from "./CallToActionWidget";
import { NewLiner } from "../../../services/ComponentToolbox";
import AppContext from "../../../AppContext";
import CallToActionConfirmationPopup from "./CallToActionConfirmationPopup";

export default function CallToAction() {
    const dict = useDictionary('callToAction');

    const idModal = "ctaPopup";
    const idModalConfirmation = "ctaPopupConfirmation";
    AppContext.modalCtaPopup = idModal;
    
    return (
        <div id="landingpage-cta-container">
            <div className="app-container">
                <div id="cta-grid">
                    <div key="text">
                        <h1>{NewLiner(dict.headingTitle)}</h1>
                        <br/>
                        <CallToActionWidget/>
                    </div>
                    <div key="image"
                        className="app-padding">
                        <img sizes="600px"
                            className="app-width-full" 
                            src={AppConfig.media.cta}/>
                    </div>
                </div>
                {/* <CallToActionPopup modalId={idModal} confirmationModal={idModalConfirmation}/>
                <CallToActionConfirmationPopup modalId={idModalConfirmation}/> */}
                {/* Commented due to the uncertain future of what will happen with this landing page - will it remain a static page linking to the App Source plugin, or will there be a promotion, and then it's possible that the popup form will come in handy again */}
            </div>
        </div>
    );
}