
import React, { createContext } from "react";
import Introduction from "./introduction/Introduction";
import CallToAction from "./callToAction/CallToAction";
import MakersTeam from "./makersTeam/MakersTeam";
import Media from "./media/Media";
import Pricing from './Pricing/Pricing'
import Roadmap from "./roadmap/Roadmap";
import SocialProof from "./socialProof/SocialProof";
import BuisnessInfo from "./buisnessInfo/BuisnessInfo";
import Timer from "./timer/Timer";
import ValueAdded from "./valueAdded/ValueAdded";
import StayTuned from "./stayTuned/StayTuned";
import CallToActionWidget from "./callToAction/CallToActionWidget";
import LandingPageComponent from "../common/LandingPageComponent";
import { CalculateCurrentPromotion } from "../../services/TimeCalculation";

export default function LandingPage(){
    CalculateCurrentPromotion();
    
    return (
        <div>
            <CallToAction/>
            <Introduction/>
            <Media/>
            {/* <Timer/> */}
            <BuisnessInfo/>
            <ValueAdded/>
            <Pricing />
            <MakersTeam/>
            <Roadmap/>
            {/* <SocialProof/> */}
            {/* <StayTuned/> */}
        </div>
    );
}
