import { CssClassBuilder } from "../../services/CssToolbox";
import { GenerateUUID } from "../../services/Toolbox";

function Stack({children, className, align, spaceBetween=16}){
    let alignment = "";
    switch (align) {
        case "right":
            alignment = "app-list-right";
            break;
        case "left":
            alignment = "app-list-left";
            break;
        case "bottom":
            alignment = "app-list-bottom";
            break;
        case "top":
            alignment = "app-list-top";
            break;    
        default:
            alignment = "app-list";
            break;
    }
    const cssClass = CssClassBuilder([
        className,
        alignment
    ]);

    const listRecordStyle = {
        margin: spaceBetween+"px",
    }
    
    return(
        <div className={cssClass}>
            {children.map( (child)=> <div style={listRecordStyle} key={GenerateUUID()}>{child}</div> 
            )}
        </div>
    );
}

export default Stack;