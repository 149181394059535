
import * as reactstrap from "reactstrap";


function Card({children, className}){
    return(
        <div className="app-padding">
            <reactstrap.Card className={className}>
                <reactstrap.CardBody>
                    {children}
                </reactstrap.CardBody>
            </reactstrap.Card>
        </div>
    );
}

export default Card;