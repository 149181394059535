
function HorizontalContainer({className = "", id, children, itemClassName = ""}){
    return(
        <div className={`app-horizontal ${className}`} id={id}>
            {children ? children.map( (child, index)=>
                <div className={`app-horizontal-item ${itemClassName}`} key={index}>
                    {child}
                </div>
            ) : <div></div>}
        </div>  
    );
};

export default HorizontalContainer;