import React from 'react';
import './Pricing.scss';

import pricing from '../../../config/pricing.json';
import { eDoAppIcon, mSzafirIcon } from '../../../services/Configuration';

import { useDictionary } from "../../../services/dictionaryService";


const Pricing = () => {
    const dictionary = useDictionary('pricing');

    return (
        <div className="pricing-container">
            <h2 className="pricing-title-text pricing-heading">{dictionary.title}</h2>
            <div className="pricing-wrapper">
                <div className="pricing-info-wrapper">
                    <span className="pricing-highlight-heading-text">{dictionary.monthUserLicense}</span>
                    <div className="pricing-cost">
                        <span className="pricing-main-price price-spacing">{pricing.monthlyLicensePrice}</span>
                        <span className="pricing-highlight-heading-text">{pricing.currency}</span>
                    </div>
                </div>
                <div className="pricing-transaction-section-wrapper">
                    <span className="pricing-highlight-heading-text pricing-transaction-heading">{dictionary.transactionPrices}</span>
                    <div className="pricing-transaction-selector">
                        <div className="pricing-contractors-offers">
                            <img
                                src={eDoAppIcon}
                                loading="lazy"
                                alt="eDO App Icon"/>
                            <span className="pricing-highlight-text fixed-width">{dictionary.edoAppPinConfirm}</span>
                            <div className="pricing-cost">
                                <span className="pricing-highlight-price price-spacing">{pricing.edoAppPinPrice}</span>
                                <span className="pricing-highlight-heading-text">{pricing.currency}</span>
                            </div>
                        </div>
                        <div className="pricing-contractors-offers">
                            <img
                                src={eDoAppIcon}
                                loading="lazy"
                                alt="eDO App Icon"/>
                            <span className="pricing-standard-text fixed-width">{dictionary.edoAppIdRead}</span>
                            <div className="pricing-cost">
                                <span className="pricing-regular-price price-spacing">{pricing.edoAppIdReadPrice}</span>
                                <span className="pricing-standard-text">{pricing.currency}</span>
                            </div>
                        </div>
                        <div className="pricing-contractors-offers">
                            <span className="pricing-standard-text pricing-contractor-no-icon fixed-width">{dictionary.biometricVerification}</span>
                            <div className="pricing-cost">
                                <span className="pricing-regular-price price-spacing">{pricing.biometricVerificationPrice}</span>
                                <span className="pricing-standard-text">{pricing.currency}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pricing-section-wrapper">
                    <div className="pricing-contractors-offers">
                        <img
                            src={eDoAppIcon}
                            loading="lazy"
                            alt="eDO App Icon"/>
                        <span className="pricing-highlight-text fixed-width">{dictionary.edoAppElectronicSign}</span>
                        <div className="pricing-cost">
                            <span className="pricing-highlight-price price-spacing">{pricing.edoAppElectronicSignPrice}</span>
                            <span className="pricing-standard-text">{pricing.currency}</span>
                        </div>
                    </div>
                    <div className="pricing-contractors-offers">
                        <span className="pricing-standard-text pricing-contractor-no-icon fixed-width">{dictionary.idNowOneTimeQES}</span>
                        <div className="pricing-cost">
                            <span className="pricing-regular-price price-spacing">{pricing.idNowOneTimeQESPrice}</span>
                            <span className="pricing-standard-text">{pricing.currency}</span>
                        </div>
                    </div>
                    <div className="pricing-contractors-offers">
                        <div style={{width:"120px"}}>
                            <img
                                src={mSzafirIcon}
                                loading="lazy"
                                alt="mSzafir App Icon"/>
                        </div>
                        <span className="pricing-standard-text fixed-width">{dictionary.mSzafirOneTimeQES}</span>
                        <div className="pricing-cost">
                            <span className="pricing-regular-price price-spacing">{pricing.mSzafirOneTimeQESPrice}</span>
                            <span className="pricing-standard-text">{pricing.currency}</span>
                        </div>
                    </div>
                </div>
            </div>
            <span className="pricing-additional-info-text net-prices">{dictionary.netPrices}</span>
        </div>
    );
}

export default Pricing;
