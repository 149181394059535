
import AppConfig from "../AppConfig.json";
import AppContext from "../AppContext";
import { useDictionary } from "../services/dictionaryService";

function CalculateCurrentPromotion(){
    const dict = useDictionary('timer');

    const timeNow = new Date();
    for (let index = 0; index < AppConfig.timerDiscounts.length; index++) {
        const promoItem = AppConfig.timerDiscounts[index];
        const promoStartTime = new Date(promoItem.start);
        const promoEndTime = new Date(promoItem.end);
        if(promoStartTime <= timeNow && timeNow <= promoEndTime){
            const promotionsKey = promoItem.promotion.toString();
            const promotionText = dict.promotions[promotionsKey];
            AppContext.currentPromotion = promotionText;
            AppContext.currentPromotionNumber = promotionsKey;
            AppContext.ctaWidgetPromotion = (AppConfig.price.PLN - (AppConfig.price.PLN * (promotionsKey / 100))).toFixed(2);
            return promotionText;
        }
    }
}


export {
    CalculateCurrentPromotion
};