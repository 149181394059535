import LandingPageComponent from "../../common/LandingPageComponent";
import { useDictionary } from "../../../services/dictionaryService";
import Grid from "../../common/Grid";
import { GenerateUUID } from "../../../services/Toolbox";
import { IconCheckCircle } from "../../common/Icons";
import Stack from "../../common/Stack";
import AppConfig from "../../../AppConfig.json";

function GetListRecordComponent(dataRecord){
    return(
        <li key={GenerateUUID()} className="valueadded-listrecord">
            <Stack align="left" spaceBetween={6}><img src={AppConfig.media.checkIcon} sizes="30px" width={30}/> {dataRecord}</Stack>
        </li>
    );
}

function ValueAdded(){
    const dict = useDictionary('valueAdded');

    return(
        <LandingPageComponent componentId="landingpage-valueadded-container">
            <h1>{dict.title}</h1>

            <ul className="app-list">
                <Grid>
                    {dict.list.map(GetListRecordComponent)}
                </Grid>
            </ul>
            
        </LandingPageComponent>
    );
}

export default ValueAdded;