import { useMemo } from 'react';
import AppConfig from "../../../AppConfig.json";
import { useDictionary } from "../../../services/dictionaryService";
import LandingPageComponent from "../../common/LandingPageComponent";
import './MakersTeam.scss';

const Circle = ({size, color, position}) => {
    const styles = {
        width: size,
        height: size,
        backgroundColor: color,
        ...position,
    };
    return(<div className="makersteam-background-circle" style={styles}></div>);
}

const MakersTeamWrapper = (props) => {
    return(
        <div className="makersteam-wrapper">
            <div className="makersteam-background">
                <Circle size="30px" color="#fff" position={{top: "80%", left: "5%"}} />
                <Circle size="30px" color="#fff" position={{top: "25%", left: "95%"}} />
                <Circle size="200px" color="#B3BFEC" position={{top: "calc(25% + 150px)", left: "calc(95% - 80px)"}} />
            </div>
            {props.children}
        </div>
    );
}

const Maker = ({name, post, picture, profilePictureAlt}) => {
    return(
        <div className="makersteam-list-item">
            <img className="makersteam-list-item-picture" src={picture} alt={`${name} – ${profilePictureAlt}`}/>
            <div className="makersteam-list-item-name">{name}</div>
            <div className="makersteam-list-item-post">{post}</div>
        </div>
    );
}

const MakersTeam = () => {
    const dict = useDictionary('makersTeam');
    const profilePictureAlt = dict?.profilePictureAlt ?? "";
    const makers = useMemo(() => AppConfig.media.makersTeam.map(maker => ({
        key: maker.key,
        picture: maker.picture,
        name: dict?.team?.[maker.key]?.name ?? "",
        post: dict?.team?.[maker.key]?.post ?? "",
        profilePictureAlt: profilePictureAlt,
    })), [dict]);

    return(
        <MakersTeamWrapper>
            <LandingPageComponent componentId="landingpage-makersteam-container">
                <h2 className="makersteam-title">{dict.title}</h2>
                <div className="makersteam-list-wrapper">
                    <div className="makersteam-list">
                        {
                            makers.map(props => <Maker {...props} />)
                        }
                    </div>
                </div>
            </LandingPageComponent>
        </MakersTeamWrapper>
    );
}

export default MakersTeam;