import React from "react";
import LandingPageComponent from "../../common/LandingPageComponent";
import { useDictionary } from "../../../services/dictionaryService";
import RoadmapImage from "./roadmap-transparent_background.svg";

import "./Roadmap.scss";

function Roadmap() {
    const dict = useDictionary('roadmap');

    return (
        <LandingPageComponent componentId="landingpage-roadmap-container">
            <h1>{dict.title}</h1>
            <div className="roadmap-image-wrapper">
                <img className="roadmap-image" src={RoadmapImage} alt="roadmap"/>
            </div>
        </LandingPageComponent>           
    );
}

export default Roadmap;