import React from "react";
import HorizontalContainer from "../common/HorizontalContainer";
import { LogoFooter } from "../../services/Configuration";
import { IconLinkedin } from "../common/Icons";
import Stack from "../common/Stack";
import { useDictionary } from "../../services/dictionaryService";
import AppConfig from "../../AppConfig.json";
import { useBusinessDocumentsWithLangFromContext } from "../../services/businessDocumentsService";

const config = AppConfig.footer;

export const Footer = () => {
  const dict = useDictionary("footer");
  const publicDocuments = useBusinessDocumentsWithLangFromContext();

  return (
    <footer id="app-footer">
      <div className="app-container">
        <div id="footer">
          <HorizontalContainer>
            <div>
              <img src={LogoFooter} />
              <br />
              <br />
              <p>
                {dict.description[0]}
                <br />
                {dict.description[1]}
              </p>
            </div>
            <Stack align="right" spaceBetween={16}>
              <a className="app-link-light" href={"mailto:" + config.email}>
                {config.email}
              </a>
              <a href={config.linkedin}>
                <IconLinkedin />
              </a>
            </Stack>
          </HorizontalContainer>
          <HorizontalContainer className="app-margin-top-32">
            <div></div>
            <HorizontalContainer>
              <a
                href={publicDocuments.discountTerms.absolutePath}
                target="_blank"
                rel="noreferrer"
              >
                {dict.promotionTOS[0]}
                <br />
                {dict.promotionTOS[1]}
              </a>
              <a
                href={publicDocuments.inperlyTerms.absolutePath}
                target="_blank"
                rel="noreferrer"
              >
                {dict.inperlyTOS[0]}
                <br />
                {dict.inperlyTOS[1]}
              </a>
              <a
                href={publicDocuments.privacyPolicy.absolutePath}
                target="_blank"
                rel="noreferrer"
              >
                {dict.privacyPolicy[0]}
                <br />
                {dict.privacyPolicy[1]}
              </a>
              <div></div>
            </HorizontalContainer>
          </HorizontalContainer>
          <p className="app-font-small">{dict.copyright}</p>
        </div>
        <div id="footer-mobile">
          <img src={LogoFooter} />
          <br />
          <p>
            {dict.description[0]}
            <br />
            {dict.description[1]}
          </p>
          <Stack align="right" spaceBetween={16}>
            <a className="app-link-light" href={"mailto:" + config.email}>
              {config.email}
            </a>
            <a href={config.linkedin}>
              <IconLinkedin />
            </a>
          </Stack>
          <HorizontalContainer id="footer-mobile-rules">
            <a
              href={publicDocuments.discountTerms.absolutePath}
              target="_blank"
              rel="noreferrer"
            >
              {dict.promotionTOS[0]}
              <br />
              {dict.promotionTOS[1]}
            </a>
            <a
              href={publicDocuments.inperlyTerms.absolutePath}
              target="_blank"
              rel="noreferrer"
            >
              {dict.inperlyTOS[0]}
              <br />
              {dict.inperlyTOS[1]}
            </a>
            <a
              href={publicDocuments.privacyPolicy.absolutePath}
              target="_blank"
              rel="noreferrer"
            >
              {dict.privacyPolicy[0]}
              <br />
              {dict.privacyPolicy[1]}
            </a>
          </HorizontalContainer>
          <p className="app-font-small app-margin-top">{dict.copyright}</p>
        </div>
      </div>
    </footer>
  );
};
