
import AppConfig from "../AppConfig.json";

const LogoNavbar = AppConfig.media.logoNavbar;
const LogoFooter = AppConfig.media.logoNavbar;

const eDoAppIcon = AppConfig.media.pricingIcons[0];
const mSzafirIcon = AppConfig.media.pricingIcons[1];

const TimerExpirationDate = AppConfig.timerExpiryDate;
const TimerLaunchDate = AppConfig.timerLaunchDate;

export {
    TimerExpirationDate,
    TimerLaunchDate,
    LogoNavbar,
    LogoFooter,
    eDoAppIcon,
    mSzafirIcon
};