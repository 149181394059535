

function LandingPageComponent(props) {
    return(
        <div id={props.componentId}>
            <div className="app-container">
                {props.children}
            </div>
        </div>
    );
}

export default LandingPageComponent;