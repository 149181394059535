
import {Swiper, SwiperSlide} from "swiper/react";
import { Autoplay, Pagination, Navigation, Scrollbar } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Carousel(props){
    return(
        <Swiper
            spaceBetween={50}
            autoplay={{
                delay: 6000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}>
            {props.children.map((child, index)=>
                <SwiperSlide key={index}>
                    <div className="app-carousel-item">
                        {child}
                    </div>
                </SwiperSlide>
            )}
        </Swiper>
    )
}