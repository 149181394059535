import React, { Component } from 'react';
import * as reactstrap from 'reactstrap';
import { Link } from 'react-router-dom';
import Dropdown from "../common/Dropdown";
import { LogoNavbar } from '../../services/Configuration';
import LanguageSelector from "../languageSelector/LanguageSelector";

export default function Navbar() {

  return (
    <header id="app-header">
      <reactstrap.Navbar>
        <reactstrap.NavbarBrand>
          <img
            src={LogoNavbar} 
            loading="lazy" 
            alt="brand-logo"/>
        </reactstrap.NavbarBrand>
        <reactstrap.NavbarText>
          <LanguageSelector/>       
        </reactstrap.NavbarText>
      </reactstrap.Navbar>
    </header>
  );
}
