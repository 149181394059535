
import React from "react";
import HorizontalContainer from "../../common/HorizontalContainer";
import LandingPageComponent from "../../common/LandingPageComponent";
import {VimeoPlayer} from "../../common/VideoPlayer";
import AppConfig from "../../../AppConfig.json";
import { useDictionary } from "../../../services/dictionaryService";

function Media() {
    const dict = useDictionary('media');

    return (
        <LandingPageComponent componentId="landingpage-media-container">
            <div id="media-list">
                <div className="app-padding">
                    <VimeoPlayer source={AppConfig.media.movies[0]}
                        title={dict.movies[0].description}/>
                    <h3 className="app-text-center">{dict.movies[0].header}</h3>
                </div>
                <div className="app-padding">
                    <VimeoPlayer source={AppConfig.media.movies[1]}
                        title={dict.movies[1].description}/>
                    <h3 className="app-text-center">{dict.movies[1].header}</h3>
                </div>
            </div>
        </LandingPageComponent>
    );
}

export default Media;