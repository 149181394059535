
import {useEffect} from "react";

export default function StaticFileNavigator({filePath}){
    useEffect(()=>{
        window.location = filePath;
    }, []);
    return(
        <div></div>
    );
}