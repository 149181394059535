import { useDictionariesSelection } from "../../services/dictionaryService";
import {Dropdown, DropdownItem} from "../common/Dropdown";

export default function LanguageSelector(){
    const [currentLangCode, currentLangDesc] = useDictionariesSelection("current");
    const langSelection = useDictionariesSelection("selection");

    return(
        <Dropdown buttonContent={currentLangDesc} withCarret={true}>
            {langSelection.map(([langCode, langDesc])=>
                <DropdownItem href={`/${langCode}`} key={langCode}>{langDesc}</DropdownItem>
            )}
        </Dropdown>  
    );
}