
import React from "react";
import LandingPageComponent from "../../common/LandingPageComponent";
import { useDictionary } from "../../../services/dictionaryService";
import HorizontalContainer from "../../common/HorizontalContainer";
import Button from "../../common/Button";

function Card({imageSource, title, subTitle}){
  return(
        <div class="card">
            <img class="card-img-top" src={imageSource}/>
            <div class="card-body">
                <h5 class="card-title">{title}</h5>
                <p class="card-text">{subTitle}</p>
            </div>
        </div>
  )
}

function SocialProof() {
    const dict = useDictionary('socialProof');

    return (
        <LandingPageComponent componentId="landingpage-socialproof-container">
            <h1>{dict.header}</h1>
            <HorizontalContainer>
                {dict.list.map( (record)=> 
                    <Card 
                        title={record.title}
                        subTitle={record.subTitle}
                        imageSource={record.imageSource}/>)}
            </HorizontalContainer>
        </LandingPageComponent>
    );
}

export default SocialProof;