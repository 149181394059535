import { Link } from "react-router-dom";
import AppContext from "../../../AppContext";
import { useDictionary, useDictionariesSelection } from "../../../services/dictionaryService";
import Button from "../../common/Button";
import { ShowModal } from "../../common/Modal";
import { ProductPrice } from "../../common/productPrice/ProductPrice";
import AppConfig from "../../../AppConfig.json";

function CallToActionWidget(){
    const dict = useDictionary('callToActionWidget');
    const language = useDictionariesSelection("current");
    const config = AppConfig;
    const appStoreUrl = config.appSource.find((item)=> item.lang === language[0]).url;

    return(
        <div className="cta-widget">
            <p className="app-font-larger">
                {dict.caption}
            </p>
            <br/>
            <br/>
            <div>
                <Button className="cta-widget-button" onClick={()=> window.open(appStoreUrl, "_blank")}>
                    {dict.save}
                </Button>
            </div>
            <br/>
            <p>{dict.note}</p>
        </div>
    );
}

export default CallToActionWidget;